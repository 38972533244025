import { configureStore } from "@reduxjs/toolkit";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";
import footerDetailsReducer, {
  footerDetails,
} from "../features/products/footerAboutSlice";

import bannerReducer, {
  bannerFetching,
} from "../features/banner/sliderBannerSlice";

import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/homeBannerSlice";

import subCategoryReducer, {
  subCategoryFetching,
} from "../features/category/subCategorySlice";
import logoReducer, { logoFatching } from "../features/logo/logoSlice";

import pagesReducer, { pageFatching } from "../features/pages/pagesSlice";
import thumbImageReducer, {
  thumbImageFetching,
} from "../features/products/thambImageSlice";
import singleProductReducer, {
  singleProductFetching,
} from "../features/products/signleProductSlice";
import singleCategoryReducer, {
  singleCategoryFetching,
} from "../features/category/singleCategorySlice";
import shippingChargeReducer, {
  shippingChargeFetching,
} from "../features/shipping/shipingCharge";

import landingPageSlice, {
  landingPageFetching,
} from "../features/landingPage/landingPageSlice";
import singleLandingPageSlice from "../features/landingPage/singleLandingPageSlice";

export const store = configureStore({
  reducer: {
    //reducer in here
    products: productReducer,
    cart: cartReducer,
    category: categoryReducer,
    bestSell: bestSellingReducer,
    logo: logoReducer,
    footerDetails: footerDetailsReducer,
    banners: bannerReducer,
    homeBanner: homeBannerReducer,
    subCategor: subCategoryReducer,
    thumbImage: thumbImageReducer,
    landingPage: landingPageSlice,
    singleLandingPage: singleLandingPageSlice,
    singleProduct: singleProductReducer,
    page: pagesReducer,
    shippingCharge: shippingChargeReducer,
    categoryProduct: singleCategoryReducer,
  },
});

store.dispatch(productFetching());
store.dispatch(categoryFetching());
store.dispatch(singleProductFetching());
store.dispatch(singleCategoryFetching());
store.dispatch(shippingChargeFetching());
store.dispatch(bestSellingFetching());
store.dispatch(footerDetails());
store.dispatch(landingPageFetching());
store.dispatch(bannerFetching());
store.dispatch(thumbImageFetching());
store.dispatch(homeBannerFetching());
store.dispatch(subCategoryFetching());
store.dispatch(logoFatching());
store.dispatch(pageFatching());
