import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { ThemeProvider } from "@material-tailwind/react";
import TagManager from "react-gtm-module";
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-PPC7PY5V9M");
const tagManagerArgs = {
  gtmId: "GTM-M7MN5TK4",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

// ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
);
